import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import { ConsumoPorMetodoMensal } from "./ConsumoPorMetodoMensal";
import { ConsumoPorMetodoDiario } from "./ConsumoPorMetodoDiario";

import api from "../../services/api";
import './RelatorioConsumoPorMetodo.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { baixarArquivo } from "../../utils/downloadUtil";
import { formataData } from '../../utils/formatadorData';

registerLocale("pt_br", pt_br);

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const RelatorioConsumoPorMetodo =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgaos: [],
				orgao: '',
				consumidoras: [],
				consumidorasOrgao: [],
				consumidora: '',
				fornecedoras: [],
				fornecedora: '',
				contratos: [],
				contrato: '',
				metodos: [],
				metodo: '',
				carregandoConsumidoras: false,
				carregandoFornecedoras: false,
				carregandoMetodos: false,
				resultadoPesquisa: {},
				dataInicial: '',
				dataFinal: '',
				tiposVisao: [],
				tipoVisao: 'C',
				considerarAplicacaoMonitora: false,
				mostrarConsumoDiario: false,
				mostrarSomenteTotalizacao: false,
				pesquisaExecutada : false,
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.handleContratoChange = this.handleContratoChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.carregarConsumidoras = this.carregarConsumidoras.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaConsumo = this.executaPesquisaConsumo.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.mostraRelatorioConsumoCSV = this.mostraRelatorioConsumoCSV.bind(this);
			this.mostraRelatorioConsumoPDF = this.mostraRelatorioConsumoPDF.bind(this);
			this.handleMostrarConsumoDiarioChange = this.handleMostrarConsumoDiarioChange.bind(this);
			this.handleTipoVisaoChange = this.handleTipoVisaoChange.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Relatório de consumo por método</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			if(this.props.user.isAdministrador === true || this.props.user.isFornecedor === true || this.props.user.isMantenedor === true)	{
				api.get('/api/orgao/consumidores/perfil-usuario')
				.then(response => { this.setState({orgaos: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar órgãos consimidores " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			if(this.props.user.isCliente === true){
				api.get('/api/aplicacao-consumidora/orgao')
				.then(response => { 
					const consumidoras = response.data;
					this.setState(
						{
							consumidoras,
							consumidorasOrgao: [...consumidoras]
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações do órgão " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			if(this.props.user.isFornecedor === true){
				api.get('/api/consumo/tipos-visao-relatorio')
				.then(response => { this.setState({tiposVisao: response.data}) })
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de visão para o relatório " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			api.get('/api/contrato/tipo/A')
			.then(response => { 
				const contratos = response.data;
				this.setState(
					{
						contratos,
					}
				) 
			})
			.catch(erro => {
				console.log("Não foi possível recuperar os contratos " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

			this.carregarFornecedoras(this.state.tipoVisao, this.state.orgao, this.state.contrato);
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const exibeBotoes = this.state.mostrarConsumoDiario ? 
				resultadoPesquisa.listaConsumo && resultadoPesquisa.listaConsumo.length > 0 :
				resultadoPesquisa && resultadoPesquisa.length > 0;

			const user = this.props.user;
			const tipoVisao = this.state.tipoVisao;
			const exibeComboOrgao = user && (user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F'));
			const exibeComboAplicacao = ((user.isCliente === true && tipoVisao === 'C') || ((user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F')) && this.state.orgao !== ''));
			const exibeComboMetodo = this.state.fornecedora !== '';
			const exibeConsiderarAplicacaoMonitora = user && (user.isAdministrador === true || user.isMantenedor === true);

			return(
				<div className="container-fluid">
					<div className="br-form">
						{user && user.isFornecedor === true &&
							<div className="row">
								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label htmlFor="tipoVisao">Mostrar:</label>
											<select name="tipoVisao" onChange={this.handleTipoVisaoChange} value={this.state.tipoVisao}>
												{
													this.state.tiposVisao.map(tipoVisao =>
														<option 
															key={tipoVisao.codigo} 
															value={tipoVisao.codigo}>
															{tipoVisao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="row">
							{ exibeComboOrgao && 
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="consumidora">Órgao Consumidor:</label>
											<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
												<option value="">Todos</option>
												{
													this.state.orgaos.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							}

							{ exibeComboAplicacao &&
							<div className="col-md">
								<div className="field">
									<div className="input">
										
										<label htmlFor="consumidora">Aplicação:</label>
										{this.state.consumidoras.length > 0 &&
											<select name="consumidora" onChange={this.handleInputChange} value={this.state.consumidora}>
												<option value="">Todas</option>
												{
													this.state.consumidoras.map(consumidora =>
														<option 
															key={consumidora.id} 
															value={consumidora.id}>
															{consumidora.nome}
														</option>
												)}
											</select>
										}
										{this.state.carregandoConsumidoras === true && 
										<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoConsumidoras === false && this.state.consumidoras.length === 0 && 'Não há aplicações para serem selecionadas.'}
									</div>
								</div>
							</div>
							}

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="contrato">Contrato:</label>
										<select name="contrato" id="contrato" onChange={this.handleContratoChange} value={this.state.contrato}>
											<option value="">Todos</option>
											{
												this.state.contratos?.map(contrato =>
													<option 
														key={contrato.id} 
														value={contrato.id}>
														{contrato.nomeContrato} {'=>'} {formataData(contrato.inicioVigencia)} a {formataData(contrato.fimVigencia)}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{this.state.fornecedoras.length > 0 &&
											<select name="fornecedora" onChange={this.handleFornecedoraChange} value={this.state.fornecedora}>
												<option value="">Todas</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{this.state.carregandoFornecedoras === true && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}

										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && 'Não há APIs para serem selecionadas.'}
									</div>
								</div>
							</div>

							{exibeComboMetodo && 
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Metodo:</label>
											{this.state.metodos.length > 0 &&
												<select name="metodo" onChange={this.handleInputChange} value={this.state.metodo}>
													<option value="">Todos</option>
														{
															this.state.metodos.map((metodo, index) =>
																<option 
																	key={index} 
																	value={metodo.operacao}>
																	{metodo.operacao}
																</option>
														)}
												</select>
											}
											{this.state.carregandoMetodos === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}

											{this.state.carregandoMetodos === false && this.state.metodos.length === 0 && 'Não há métodos para serem selecionados.'}
										</div>
									</div>
								</div>
							}

						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Inicial:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataInicial} onChange={data => {this.setState({dataInicial: data})}} />
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Data Final:</label>
										<DatePicker locale="pt_br" dateFormat="dd/MM/yyyy" selected={this.state.dataFinal} onChange={data => {this.setState({dataFinal: data})}} />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="check">
									<div className="input">
										<input type="checkbox" name="mostrarConsumoDiario" checked={this.state.mostrarConsumoDiario} onChange={this.handleMostrarConsumoDiarioChange} />
										<label>Mostrar consumo diário</label>
									</div>
								</div>
							</div>

							{ this.state.mostrarConsumoDiario === false &&			
								<div className="col-md">
									<div className="check">
										<div className="input">
											<input type="checkbox" name="mostrarSomenteTotalizacao" checked={this.state.mostrarSomenteTotalizacao} onChange={this.handleInputChange} />
											<label>Mostrar somente totais</label>
										</div>
									</div>
								</div>
							}

							{ exibeConsiderarAplicacaoMonitora &&			
								<div className="col-md">
									<div className="check">
										<div className="input">
											<input type="checkbox" name="considerarAplicacaoMonitora" checked={this.state.considerarAplicacaoMonitora} onChange={this.handleInputChange} />
											<label>Mostrar consumo das aplicações de monitoração</label>
										</div>
									</div>
								</div>
							}
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioConsumoPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioConsumoCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						{! this.state.mostrarConsumoDiario && 
							<ConsumoPorMetodoMensal resultadoPesquisa={resultadoPesquisa} pesquisaExecutada={pesquisaExecutada} onChangePage={this.executaPesquisaConsumo}/>
						}

						{ this.state.mostrarConsumoDiario && 
							<ConsumoPorMetodoDiario resultadoPesquisa={resultadoPesquisa} pesquisaExecutada={pesquisaExecutada}/>
						}
					</div>
				</div>
			);
		}

		validaFormulario(){
			let mensagens = [];
			const {orgao, fornecedora, dataInicial, dataFinal, mostrarConsumoDiario, tipoVisao} = this.state;
			const user = this.props.user;

			if(mostrarConsumoDiario === true){
				if(user && (user.isAdministrador === true || user.isMantenedor === true || (user.isFornecedor === true && tipoVisao === 'F')) && !orgao){
					mensagens.push({mensagem: "Órgão é obrigatório."})
				}
				if(!fornecedora){
					mensagens.push({mensagem: "API é obrigatório."})
				}
				if(!dataInicial){
					mensagens.push({mensagem: "Data Inicial é obrigatório."})
				}
				if(!dataFinal){
					mensagens.push({mensagem: "Data Final é obrigatório"})
				}
			}

			if(dataInicial && dataFinal && dataInicial > dataFinal){
				mensagens.push({mensagem: "A data inicial deve ser menor ou igual à data final"})
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleTipoVisaoChange(evento){
			const tipoVisao =  evento.target.value;
			const orgao = "";
			const contrato = "";
			let consumidoras = [];
			let fornecedoras = [];
			const metodos = [];
			const metodo = "";
			if(tipoVisao === 'C') {
				consumidoras = [...this.state.consumidorasOrgao];
			} 

			this.setState({
				tipoVisao,
				consumidoras,
				consumidora: "",
				fornecedoras,
				fornecedora: "",
				metodos,
				metodo,
				orgao,
				contrato,
			});

			this.carregarFornecedoras(tipoVisao, orgao, contrato);
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;
			const idTipoVisao = this.state.tipoVisao;
			const idContrato = this.state.contrato;

			this.setState({
				consumidora: "",
				fornecedora: "",
				metodos: [],
				metodo: "",
				orgao: idOrgao,
			});

			this.carregarConsumidoras(idOrgao);
			this.carregarFornecedoras(idTipoVisao, idOrgao, idContrato);
		}

		handleContratoChange(evento){
			const contrato =  evento.target.value;
			const tipoVisao = this.state.tipoVisao;
			const orgao = this.state.orgao;

			this.setState({
				fornecedora: "",
				contrato,
			});

			this.carregarFornecedoras(tipoVisao, orgao, contrato);
		}

		carregarConsumidoras(idOrgao) {
			if(idOrgao && idOrgao !== ""){
				this.setState({carregandoConsumidoras : true});
				api.get(`/api/aplicacao-consumidora/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							consumidoras: response.data,
							carregandoConsumidoras : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoConsumidoras : false});
					console.log("Não foi possível recuperar as aplicações associadas ao órgão " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento das aplicações do órgão.")
				});
			}
		}

		carregarFornecedoras(idTipoVisao, idOrgao, idContrato) {
			this.setState(
				{
					fornecedora: "",
					carregandoFornecedoras : true
				}
			);
			api.get(`/api/aplicacao-fornecedora/tipo-visao/${idTipoVisao}/orgao-consumidor?idOrgaoConsumidor=${idOrgao}&idContrato=${idContrato}`)
			.then(response => { 
				const fornecedoras = response.data;
				this.setState(
					{
						fornecedoras,
						carregandoFornecedoras : false,
					}
				)
			 })
			.catch(erro => {
				this.setState({carregandoFornecedoras : false});
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		handleMostrarConsumoDiarioChange(evento){
			const mostrarConsumoDiario =  evento.target.checked;

			this.setState({
				mostrarConsumoDiario,
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
		}

		handleFornecedoraChange (evento) {
			const fornecedora =  evento.target.value;
			this.setState({
				fornecedora,
				metodos: [],
				metodo: "",
			});
			this.carregarMetodos(fornecedora);
		}

		carregarMetodos(idFornecedora) {
			if(idFornecedora && idFornecedora !== ""){
				this.setState({carregandoMetodos : true});
				api.get(`/api/metodo/aplicacao-fornecedora/${idFornecedora}`)
				.then(response => { 
					this.setState(
						{
							metodos: response.data,
							carregandoMetodos : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoMetodos : false});
					console.log(`Não foi possível recuperar os métodos associados à API ${idFornecedora}`, erro);
					this.props.exibeMensagemErro("Erro durante o carregamento dos métodos da API.")
				});
			}
		}


		pesquisar (e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}
			this.executaPesquisaConsumo();
		}

		executaPesquisaConsumo(){

			const {consumidora, fornecedora, metodo, orgao, contrato, dataInicial, dataFinal, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteTotalizacao } = this.state;
			const criterioPesquisa = {consumidora, fornecedora, metodo, orgao, contrato, dataInicial, dataFinal, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteTotalizacao };

			let url;
			if (this.state.mostrarConsumoDiario) {
				url = '/api/consumo-por-metodo/diario';
			} else{
				url = '/api/consumo-por-metodo/mensal';
			}

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar o consumo ", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		mostraRelatorioConsumoCSV(e){
			this.mostraRelatorioConsumo(e, 'csv');
		}

		mostraRelatorioConsumoPDF(e){
			this.mostraRelatorioConsumo(e, 'pdf');
		}

		mostraRelatorioConsumo(e, tipo){
			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const {consumidora, fornecedora, metodo, orgao, contrato, dataInicial, dataFinal, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteTotalizacao } = this.state;
			const criterioPesquisa = {consumidora, fornecedora, contrato, metodo, orgao, dataInicial, dataFinal, tipoVisao, considerarAplicacaoMonitora, mostrarSomenteTotalizacao };
			const periodicidade = this.state.mostrarConsumoDiario? 'diario' : 'mensal';
			const url = `/api/consumo-por-metodo/${periodicidade}/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioConsumo.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de consumo';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const tipoVisao = 'C';
			const orgao = '';
			const contrato = '';
			this.setState({
				orgao,
				consumidoras: this.props.user.isAdministrador === true || this.props.user.isMantenedor === true ? [] : [...this.state.consumidorasOrgao],
				consumidora: '',
				fornecedora: '',
				contrato,
				metodo: '',
				resultadoPesquisa: {},
				pesquisaExecutada : false,
				mostrarConsumoDiario : false,
				tipoVisao,
				dataInicial: '',
				dataFinal: '',
				considerarAplicacaoMonitora: false,
				mostrarSomenteTotalizacao: false,
			});
			this.carregarFornecedoras(tipoVisao, orgao, contrato);
		}
	}

) // redux
); // router