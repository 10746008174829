import React, { Component, Fragment } from "react";
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
const Parser = require('html-to-react').Parser;

export const ConfirmModal =

class extends Component {
	render() {
		return <Modal
            title={<Fragment>
                      <FontAwesomeIcon icon={faQuestionCircle} size="2x" style={{color: "#C9302C"}}/> {this.props.titulo}
                    </Fragment>
                  }
		      open={this.props.visible}
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
          maskClosable = {false}
          okText={this.props.okText ? this.props.okText : 'OK'}
          cancelText={this.props.cancelText ? this.props.cancelText : 'Cancel'}
          width={this.props.width ? this.props.width : 416}
          centered
        >
          <p>{Parser().parse(this.props.message)}</p>
          {this.props.children}
        </Modal>
	}

}