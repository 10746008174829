import React, { Component } from "react";
import { AlertModal } from "../AlertModal/AlertModal";
import './SolicitacaoAdesao.css';

export const ModalIntrucoesPreenchimentoFinalidade =

class extends Component {
	render() {
		return <AlertModal
          titulo={this.props.titulo}
		      visible={this.props.visible}
          handleClose={this.props.handleClose}
        >
          <div className="container-body">

            <p>
            <b>1. Hipótese de Tratamento</b></p>
            <p >Selecione a
            hipótese aplicável conforme os artigos 7º e 11 da LGPD:</p>
            <ul>
              <li><p><b>Execução
              de Políticas Públicas</b>: Implementação
              de políticas previstas em leis, regulamentos ou instrumentos
              congêneres.</p></li>
              <li><p><b>Cumprimento
              de Obrigação Legal</b>: Uso obrigatório
              por previsão legal ou regulatória.</p></li>
              <li><p><b>Outro,
              especificar</b>: Caso se aplique outra
              base legal.</p></li>
            </ul>
            <p><b>2. Como o dado
            será utilizado</b></p>
            <p>Descreva a
            finalidade do uso, exemplificando:</p>
            <ul>
              <li><p><b>Validação
              Cadastral</b>: Conferência automática de
              dados.</p></li>
              <li><p><b>Integração
              Estatal</b>: Compartilhamento entre
              órgãos.</p></li>
              <li><p><b>Certidões</b>:
              Validação automática de documentos.</p></li>
            </ul>
            <p><b>3. Políticas
            Públicas Impactadas</b></p>
            <p>Informe as
            políticas beneficiadas, como:</p>
            <ul>
              <li><p><b>Educação
              Superior, Pesca Artesanal, Programa Bolsa Família, Licenciamento
              Ambiental</b></p></li>
            </ul>
            <p><b>4. Previsão
            Legal</b></p>
            <p>Informe a base
            legal que fundamenta o tratamento, como:</p>
            <ul>
              <li><p><b>Leis</b>:
              Exemplo: Lei nº 12.527/2011 (LAI).</p></li>
              <li><p><b>Normas
              Internas</b>: Regulamentos do órgão.</p></li>
              <li><p><b>Outros
              Normativos</b>: Portarias, decretos,
              resoluções.</p></li>
            </ul>
            <p><b>5. Benefícios
            ao Cidadão</b></p>
            <p>Destaque
            vantagens diretas:</p>
            <ul>
              <li><p><b>Menos
              Burocracia</b>: Eliminação de documentos
              físicos.</p></li>
              <li><p><b>Facilidade
              de Acesso</b>: Agilidade em benefícios.</p></li>
              <li><p><b>Mais
              Segurança</b>: Redução de fraudes.</p></li>
            </ul>
            <p><b>6. Duração
            do Tratamento</b></p>
            <p>Informe o prazo
            de armazenamento e critérios adotados, sugerindo a <b>Tabela
            de Temporalidade do CONARQ</b> (<font color="#467886"><u><a href="https://www.gov.br/arquivonacional/pt-br/servicos/gestao-de-documentos/orientacao-tecnica-1/codigo-de-classificacao-e-tabela-de-temporalidade-e-destinacao-de-documentos-de-arquivo" target="_blank" rel="noopener noreferrer">link</a></u></font>).</p>
            <p><br/>
            <br/>
            </p>

          </div>
        </AlertModal>
	}

}