import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";

import './FileUpload.css';

export const FileUpload =

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				erroSelecaoArquivo: false,
			};

			this.handleFileTypeSizeError = this.handleFileTypeSizeError.bind(this);
		}

		render() {
			const fileTypes = this.props.fileTypes;
			const multiple = this.props.multiple ? true : false;
			const maxSize = this.props.maxSize ? this.props.maxSize : 3;
			const label = "Arraste ou selecione o" + (multiple ? "(s)" : "") +  " arquivo" + (multiple ? "(s)" : "");

			return (
				<div className="col-md">
					<FileUploader handleChange={this.handleFileChange} 
						name="file" types={fileTypes} multiple={multiple}
						maxSize={maxSize}
						label={label}
						onSizeError={this.handleFileTypeSizeError}
						onTypeError={this.handleFileTypeSizeError}
						/>

					{this.state.erroSelecaoArquivo &&	
						<div className="div-erro">Os arquivos devem ser do(s) tipo(s) [{fileTypes.toString()}] com até {maxSize}Mb</div>
					}
				</div>
			);
		}

		handleFileChange = (objectFile) => {
			this.setState({
				erroSelecaoArquivo: false,
			})

			let files = objectFile;
			if(! this.props.multiple) {
				files = [objectFile];
			}
			this.props.onFileChange(files);
		};

		handleFileTypeSizeError =  () => {
			this.setState({
				erroSelecaoArquivo: true,
			})
		}

	
}
