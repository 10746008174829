import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faUserPen, faUserCheck, faClipboardCheck, faMagnifyingGlass, faDownload, faFileArrowDown, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import api from "../../services/api";
import { baixarArquivo } from "../../utils/downloadUtil";

import './SolicitacaoAdesao.css';
import { TIPO_SOLICITACAO_ADESAO_INCLUSAO, TIPO_SOLICITACAO_ADESAO_ALTERACAO } from "../../providers/constTiposSolicitacaoAdesao";
import { 	SITUACAO_SOLICITACAO_ADESAO_RASCUNHO, SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA, SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE,
			SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA, SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE, SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA,
			SITUACAO_SOLICITACAO_ADESAO_DEFERIDA,
			ID_SITUACAO_SOLICITACAO_ADESAO_RASCUNHO,
			ID_SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA
 } from "../../providers/constSituacoesSolicitacaoAdesao";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

const tipoVisaoConsumidor = 'C';
const tipoVisaoFornecedor = 'F';
const todasSituacoes = [
	SITUACAO_SOLICITACAO_ADESAO_RASCUNHO,
	SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA,
	SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_CEDENTE,
	SITUACAO_SOLICITACAO_ADESAO_EM_ANALISE_GESTOR_PLATAFORMA,
	SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE,
	SITUACAO_SOLICITACAO_ADESAO_DEFERIDA_SEM_VOLUMETRIA,
	SITUACAO_SOLICITACAO_ADESAO_DEFERIDA
];

export const SolicitacaoAdesaoList =

// router
withRouter(
// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					numero: '',
					orgao: '',
					orgaoFornecedora: '',
					situacao: '',
					tipo: '',
					fornecedora: '',
					tipoVisao: tipoVisaoConsumidor,
				},
				orgaos: [],
				orgaosFornecedores: [],
				situacoes: [...todasSituacoes],
				tipos: [TIPO_SOLICITACAO_ADESAO_INCLUSAO, TIPO_SOLICITACAO_ADESAO_ALTERACAO],
				fornecedoras: [],
				tiposVisao: [],
				carregandoFornecedoras: false,
				carregandoOrgaos: false,
				carregandoOrgaosFornecedores: false,
				carregandoTiposVisao: false,
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				botoesHabilitados : true,
				exibeModalConfirmacao: false,
				msgModal: '',
				operacao: null,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleTipoVisaoChange = this.handleTipoVisaoChange.bind(this);
			this.handleOrgaoFornecedoraChange = this.handleOrgaoFornecedoraChange.bind(this);
			this.carregarFornecedoras = this.carregarFornecedoras.bind(this);
			this.carregaSituacoes = this.carregaSituacoes.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.fechaModal = this.fechaModal.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.excluirSolicitacao = this.excluirSolicitacao.bind(this);
			this.liberarSolicitacaoAssinatura = this.liberarSolicitacaoAssinatura.bind(this);
			this.mostraRelatorioCSV = this.mostraRelatorioCSV.bind(this);
			this.mostraRelatorioPDF = this.mostraRelatorioPDF.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Solicitações de Adesão e Alteração de Volumetria dos Órgãos</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			
			const user = this.props.user;
			let tipoVisao = tipoVisaoConsumidor;

			this.setState({
				carregandoOrgaosFornecedores: true,
				tipoVisao,
			})

			api.get('/api/aplicacao-fornecedora/disponivel-adesao-orgao/orgaos')
				.then(response => { 
					this.setState({orgaosFornecedores: response.data}); 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos cedentes." + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				}).finally(() => {
					this.setState({carregandoOrgaosFornecedores: false});
				});

			if(user.isAdministrador || user.isFornecedor){
				this.setState({carregandoOrgaos: true})
				api.get('/api/orgao/clientes?ativos=true')
					.then(response => { 
						this.setState({orgaos: response.data }); 
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar os órgãos.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					}).finally(() => {
						this.setState({carregandoOrgaos: false});
					});

				if(user.isFornecedor){
					tipoVisao = tipoVisaoFornecedor;
					this.carregaSituacoes(tipoVisao);
					const { criterioPesquisa } = this.state;
					criterioPesquisa.tipoVisao = tipoVisao;
					this.setState({ carregandoTiposVisao: true, criterioPesquisa });
					api.get('/api/consumo/tipos-visao-relatorio')
					.then(response => { this.setState({tiposVisao: response.data}) })
					.catch(erro => {
						console.log("Não foi possível recuperar os tipos de visão para a consulta.", erro)
						this.props.exibeMensagemErro("Erro durante o carregamento da página")
					}).finally(() => {
						this.setState({carregandoTiposVisao: false});
					});
				}
			}

			this.carregarFornecedoras(tipoVisao, '');
		}

		renderForm() {
			const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });
			const user = this.props.user;

			const { 
				criterioPesquisa, resultadoPesquisa, pesquisaExecutada, orgaos, orgaosFornecedores, 
				situacoes, tipos, fornecedoras, carregandoOrgaos, carregandoOrgaosFornecedores,
				carregandoFornecedoras, carregandoTiposVisao
			} = this.state;
			const { content } = resultadoPesquisa;
			const exibeBotoes = content && content.length > 0;
			const { numero, orgao, orgaoFornecedora, situacao, tipo, fornecedora, tipoVisao } = criterioPesquisa;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalConfirmacao}
						handleOk={() => this.executaOperacao()}
						handleCancel={() => this.fechaModal()}
						message={this.state.msgModal}/>

					<div className="br-form">
						<div className="row">
							{user && user.isFornecedor === true &&
								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label htmlFor="tipoVisao">Mostrar:</label>
											{carregandoTiposVisao === false &&
												<select name="tipoVisao" onChange={this.handleTipoVisaoChange} value={tipoVisao}>
													{
														this.state.tiposVisao.map(tipoVisao =>
															<option 
																key={tipoVisao.codigo} 
																value={tipoVisao.codigo}>
																{tipoVisao.nomeConsultaSolicitacaoAdesao}
															</option>
													)}
												</select>
											}
											{ carregandoTiposVisao === true  && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Número solicitação:</label>
										<input type="text" name="numero" 
											value={numero}  
											onChange={this.handleInputChange} maxLength={11}/>
										
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Tipo:</label>
										<select name="tipo" onChange={this.handleInputChange} value={tipo}>
											<option value="">Todos</option>
												{
													tipos.map(tipo =>
														<option 
															key={tipo.codigo} 
															value={tipo.codigo}>
															{tipo.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Situação:</label>
										<select name="situacao" onChange={this.handleInputChange} value={situacao}>
											<option value="">Todas</option>
												{
													situacoes.map(situacao =>
														<option 
															key={situacao.id} 
															value={situacao.id}>
															{situacao.nome}
														</option>
												)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							{(user.isAdministrador || (user.isFornecedor && tipoVisao === tipoVisaoFornecedor)) &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão solicitante:</label>
											{ carregandoOrgaos === false &&
												<select name="orgao" onChange={this.handleInputChange} value={orgao}>
													<option value="">Todos</option>
														{
															orgaos.map(orgao =>
																<option 
																	key={orgao.id} 
																	value={orgao.id}>
																	{orgao.nome}
																</option>
														)}
												</select>
											}
											{ carregandoOrgaos === true  && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}
							{(!(user.isFornecedor && tipoVisao === tipoVisaoFornecedor)) &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão cedente dos dados:</label>
											{ carregandoOrgaosFornecedores === false  && 
												<select name="orgaoFornecedora" onChange={this.handleOrgaoFornecedoraChange} value={orgaoFornecedora}>
													<option value="">Todos</option>
														{
															orgaosFornecedores.map(orgao =>
																<option 
																	key={orgao.id} 
																	value={orgao.id}>
																	{orgao.nome}
																</option>
														)}
												</select>
											}
											{ carregandoOrgaosFornecedores === true  && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API:</label>
										{ carregandoFornecedoras === false  && 
											<select name="fornecedora" onChange={this.handleInputChange} value={fornecedora}>
												<option value="">Todos</option>
													{
														fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{ carregandoFornecedoras === true  && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								{user.isCliente &&
									<Link to="/solicitacao-adesao/new"> 
										<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Nova</button>
									</Link>
								}
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
												<th scope="col">Número</th>
												<th scope="col">Tipo</th>
												<th scope="col">Órgão solicitante</th>
												<th scope="col">API/Órgão cedente</th>
												<th scope="col">Situação</th>
												<th scope="col" className="text-right">Volumetria atual</th>
												<th scope="col" className="text-right">Volumetria anual solicitada</th>
												<th scope="col" className="text-right">Volumetria autorizada</th>
												<th scope="col">Último andamento</th>
												<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((solicitacao, index) =>
													<tr key={index} >
														<td>{solicitacao.numero}</td>
														<td>{solicitacao.nomeTipo}</td>
														<td>{solicitacao.nomeOrgao}</td>
														<td>{solicitacao.nomeFornecedora} ({solicitacao.nomeOrgaoFornecedora})</td>
														<td>{solicitacao.nomeSituacao}</td>
														<td className="text-right">{numberFormater.format(solicitacao.volumetriaAtual)}</td>
														<td className="text-right">{numberFormater.format(solicitacao.volumetriaSolicitada)}</td>
														<td className="text-right">{numberFormater.format(solicitacao.volumetriaAutorizada)}</td>
														<td>{solicitacao.dataUltimoAndamento}</td>
														<td className="text-center">
															<div className="actions">
																{ solicitacao.exclusaoPermitida &&
																	<button type="button" onClick={() => this.confirmaOperacao(solicitacao, this.excluirSolicitacao, "Deseja realmente excluir a solicitação de adesão?")} 
																			disabled={!this.state.botoesHabilitados}  
																			title="Excluir a solicitação de adesão">
																		<span className="sr-only">Excluir</span>
																		<FontAwesomeIcon icon={faTrash} id="danger" />
																	</button>
																}

																{ solicitacao.alteracaoPermitida &&
																	<Link to={`/solicitacao-adesao/edit/${solicitacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar a solicitação de adesão">
																			<span className="sr-only">Editar</span>
																			<FontAwesomeIcon icon={faEdit} style={{color: "#1351b4"}} />
																		</button>
																	</Link>
																}

																{ solicitacao.liberacaoAssinaturaPermitida &&
																	<button type="button" onClick={() => this.confirmaOperacao(solicitacao, this.liberarSolicitacaoAssinatura, "Deseja realmente liberar a solicitação para assinatura?<br/><br/>Certifique-se de que o campo Finalidade está preenchido corretamente, seguindo as instruções disponíveis na seção de ajuda desse campo. Caso contrário, sua solicitação <b>poderá ser recusada</b>.")}  
																			disabled={!this.state.botoesHabilitados}  
																			title="Liberar a solicitação de adesão para assinatura">
																		<span className="sr-only">Liberar para assinatura</span>
																		<FontAwesomeIcon icon={faArrowTrendUp} style={{color: "#1351b4"}} />
																	</button>
																}

																{ solicitacao.assinaturaPermitida &&
																	<Link to={`/solicitacao-adesao/sign/${solicitacao.id}`}>
																		<button type="button" 
																				disabled={!this.state.botoesHabilitados}  
																				title="Assinar a solicitação de adesão">
																			<span className="sr-only">Assinar solicitação de adesão</span>
																			<FontAwesomeIcon icon={faUserPen} style={{color: "#1351b4"}} />
																		</button>
																	</Link>
																}

																{ solicitacao.aprovacaoFornecedoraPermitida &&
																	<Link to={`/solicitacao-adesao/approve/${solicitacao.id}`}>
																		<button type="button" 
																				disabled={!this.state.botoesHabilitados}  
																				title="Aprovar ou rejeitar a solicitação de adesão">
																			<span className="sr-only">Aprovar ou rejeitar solicitação de adesão</span>
																			<FontAwesomeIcon icon={faClipboardCheck} style={{color: "#1351b4"}} />
																		</button>
																	</Link>
																}

																{ solicitacao.aprovacaoAdministradorPermitida &&
																	<Link to={`/solicitacao-adesao/approve/${solicitacao.id}`}>
																		<button type="button" 
																				disabled={!this.state.botoesHabilitados}  
																				title="Deferir a solicitação de adesão">
																			<span className="sr-only">Deferir solicitação de adesão</span>
																			<FontAwesomeIcon icon={faUserCheck} style={{color: "#1351b4"}} />
																		</button>
																	</Link>
																}

																{ solicitacao.visualizacaoDAIPermitida &&
																	<button type="button" onClick={() => this.baixarDAIPDF(solicitacao.id)}  
																			disabled={!this.state.botoesHabilitados}  
																			title="Baixar o documento de autorização de interoperabilidade">
																		<span className="sr-only">Baixar o documento de autorização de interoperabilidade</span>
																		<FontAwesomeIcon icon={faFileArrowDown} style={{color: "#1351b4"}} />
																	</button>
																}

																{ solicitacao.visualizacaoTermoAdesaoPermitida &&
																	<button type="button" onClick={() => this.baixarTermoAdesaoPDF(solicitacao.id)}  
																			disabled={!this.state.botoesHabilitados}  
																			title="Baixar o termo de adesão">
																		<span className="sr-only">Baixar o termo de adesão</span>
																		<FontAwesomeIcon icon={faDownload} style={{color: "#1351b4"}} />
																	</button>
																}

																{ solicitacao.visualizacaoPermitida &&
																	<Link to={`/solicitacao-adesao/view/${solicitacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Visualizar a solicitação de adesão">
																			<span className="sr-only">Visualizar</span>
																			<FontAwesomeIcon icon={faMagnifyingGlass} style={{color: "#1351b4"}} />
																		</button>
																	</Link>
																}
															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhuma solicitação encontrada com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		handleTipoVisaoChange(evento){
			const tipoVisao =  evento.target.value;
			const orgao = '';

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa.tipoVisao = tipoVisao;
			criterioPesquisa.orgao = orgao;
			criterioPesquisa.orgaoFornecedora= '';
			criterioPesquisa.fornecedora = '';
			criterioPesquisa.situacao = '';
			this.setState({ criterioPesquisa });
			
			this.carregaSituacoes(tipoVisao);
			this.carregarFornecedoras(tipoVisao, orgao);
		}

		handleOrgaoFornecedoraChange(evento){
			const orgaoFornecedora =  evento.target.value;
			const tipoVisao = this.state.criterioPesquisa.tipoVisao;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa.orgaoFornecedora = orgaoFornecedora;
			criterioPesquisa.fornecedora = '';
			this.setState({ criterioPesquisa });

			this.carregarFornecedoras(tipoVisao, orgaoFornecedora);
		}

		carregarFornecedoras(idTipoVisao, idOrgao) {
			this.setState({carregandoFornecedoras : true});
			api.get(`/api/aplicacao-fornecedora/disponivel-adesao-orgao/tipo-visao/${idTipoVisao}/orgao-fornecedor?idOrgaoFornecedor=${idOrgao}`)
				.then(response => { 
					this.setState({fornecedoras: response.data})
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				})
				.finally(() => {
					this.setState({carregandoFornecedoras: false})
				});
		}

		carregaSituacoes(idTipoVisao){
			let situacoes = [...todasSituacoes];
			if(this.props.user.isFornecedor && idTipoVisao === tipoVisaoFornecedor){
				situacoes = situacoes.filter(s => s.id !== ID_SITUACAO_SOLICITACAO_ADESAO_RASCUNHO && s.id !== ID_SITUACAO_SOLICITACAO_ADESAO_AGUARDANDO_ASSINATURA);
			}
			this.setState({situacoes});
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){
			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/solicitacao-adesao/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as solicitações de adesão. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			const tipoVisao = this.props.user.isFornecedor ? tipoVisaoFornecedor : tipoVisaoConsumidor;
			this.carregaSituacoes(tipoVisao);
			const orgao = '';
			this.setState(
				{
					criterioPesquisa: {
						numero: '',
						orgao,
						orgaoFornecedora: '',
						situacao: '',
						tipo: '',
						fornecedora: '',
						tipoVisao,
					},
					resultadoPesquisa: {},
					pesquisaExecutada: false,
					exibeModalConfirmacao: false,
					botoesHabilitados : true,
				}
			)
			this.carregarFornecedoras(tipoVisao, orgao);
		}

		confirmaOperacao(solicitacao, operacao, msgModal) {
			this.setState(
				{
					exibeModalConfirmacao: true,
					solicitacaoAlvo: solicitacao,
					operacao,
					msgModal,
				}
			);
		}

		executaOperacao () {
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			this.state.operacao(this.state.solicitacaoAlvo);
			this.fechaModal();
		}

		excluirSolicitacao (solicitacao){
			api.delete(`/api/solicitacao-adesao/${solicitacao.id}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					this.props.exibeMensagemSucesso("Solicitação de adesão excluída com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir a solicitação de adesão.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		liberarSolicitacaoAssinatura (solicitacao){
			api.put(`/api/solicitacao-adesao/liberar-assinatura/${solicitacao.id}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					this.props.exibeMensagemSucesso("Solicitação de adesão liberada para assinatura com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível liberar a solicitação de adesão para assinatura.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		fechaModal () {
			this.setState(
				{
					exibeModalConfirmacao: false,
				}
			);
		}

		mostraRelatorioCSV(e){
			this.mostraRelatorio(e, 'csv');
		}

		mostraRelatorioPDF(e){
			this.mostraRelatorio(e, 'pdf');
		}

		mostraRelatorio(e, tipo){
			const { criterioPesquisa } = this.state;
			const url = `/api/solicitacao-adesao/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioSolicitacoesAdesoes.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de solicitações de adesão';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		baixarTermoAdesaoPDF(id){
            const options = {
				method: 'post',
				url: `/api/solicitacao-adesao/termo-adesao/pdf/${id}`,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({ botoesHabilitados : false });

			api(options)
				.then((response) => {
                    let nomeArquivo = 'TermoAdesao.pdf';

					const contentDisposition = response['headers']['content-disposition'];
					if(contentDisposition) {
						nomeArquivo = contentDisposition.split("filename=")[1];
					}

                    baixarArquivo(new Blob([response.data]), nomeArquivo);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o termo de adesão';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		baixarDAIPDF(id){
            const options = {
				method: 'post',
				url: `/api/solicitacao-adesao/dai/pdf/${id}`,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({ botoesHabilitados : false });

			api(options)
				.then((response) => {
                    let nomeArquivo = 'DocumentoAutorizacaoInteroperabilidade.pdf';

					const contentDisposition = response['headers']['content-disposition'];
					if(contentDisposition) {
						nomeArquivo = contentDisposition.split("filename=")[1];
					}

                    baixarArquivo(new Blob([response.data]), nomeArquivo);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o documento de autorizacao de interoperabilidade';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}
	}

) // redux
); // router