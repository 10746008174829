const numberFormater = new Intl.NumberFormat('pt-BR', {useGrouping: true });

const validaCamposNumericos = function (mensagens, valor, nomeCampo, obrigatorio, permiteNegativo, inteiro, valorMaximo){
    if (obrigatorio === true && !valor) {
        mensagens.push({mensagem: `O campo ${nomeCampo} é obrigatório.`});
    } else if (!permiteNegativo && valor < 0) {
        mensagens.push({mensagem: `O campo ${nomeCampo} ser maior ou igual a zero.`});
    } else {
        const numValor = Number(valor);
        if(numValor > valorMaximo){
            mensagens.push({mensagem: `O campo ${nomeCampo} deve ser um número menor que ${numberFormater.format(valorMaximo)}`});
        }

        if(inteiro === true && (String(valor).indexOf(".") >= 0 || String(valor).indexOf(",") >= 0 || !Number.isInteger (numValor))) {
            mensagens.push({mensagem: `O campo ${nomeCampo} deve ser um número inteiro.`});
        }
    }
}

export {validaCamposNumericos}